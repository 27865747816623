// Automatic Global Registration of Base Components
// https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const componentFiles = import.meta.glob('../Shared/**/*.vue', { eager: true });

Object.entries(componentFiles).forEach(([path, m]) => {
  const componentName = upperFirst(
    camelCase(
      path
        .split('/')
        .pop()
        .replace(/\.\w+$/, ''),
    ),
  );

  Vue.component(componentName, m.default);
});
