<i18n>
{
  "en": {
    "title": "Settings",
    "delete": "Delete",
    "delete-confirm": "Are you sure you want to delete this site? All posts and comments will be deleted, too!",
    "save": "Save"
  },
  "de": {
    "title": "Einstellungen",
    "delete": "Löschen",
    "delete-confirm": "Diese Seite wirklich löschen? Alle Beiträge und Kommentare werden dann ebenfalls gelöscht!",
    "save": "Speichern"
  }
}
</i18n>

<template>
  <div>
    <page-title :title="$t('title')" />

    <page-content>
      <site-form v-model="form" @submit="submit" />

      <footer-actions>
        <div class="flex w-full justify-between">
          <action-button
            v-if="$page.props.auth.can.destroy_site"
            as="button"
            type="button"
            icon="trash"
            :title="$t('delete')"
            tabindex="-1"
            @click="destroy"
          />

          <action-button
            v-if="$page.props.auth.can.update_site"
            :loading="sending"
            as="button"
            type="button"
            kind="primary"
            @click="submit"
          >
            {{ $t('save') }}
          </action-button>
        </div>
      </footer-actions>
    </page-content>
  </div>
</template>

<script>
import SiteForm from './_Form.vue';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },
  components: {
    SiteForm,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: this.site,
    };
  },
  methods: {
    submit() {
      let data = new FormData();
      data.append('site[name]', this.form.name);
      data.append('site[host]', this.form.host);
      data.append('site[description]', this.form.description);
      data.append('site[logo_large]', this.form.logo_large || '');
      data.append('site[logo_small]', this.form.logo_small || '');
      data.append('site[public]', this.form.public);
      data.append('site[signup_enabled]', this.form.signup_enabled);
      data.append('site[comments_enabled]', this.form.comments_enabled);
      data.append('_method', 'put');

      this.$inertia.put(this.$routes.site(), data, {
        onStart: () => (this.sending = true),
        onFinish: () => {
          this.sending = false;
          if (!this.$page.props.errors) {
            this.form.logo_large = null;
            this.form.logo_small = null;
          }
        },
      });
    },

    destroy() {
      if (confirm(this.$t('delete-confirm'))) {
        this.$inertia.delete(this.$routes.site());
      }
    },
  },
};
</script>
