<i18n>
{
  "en": {
    "title": "Error",
    "403-header": "Access denied",
    "404-header": "Error 404",
    "500-header": "Error 500",
    "503-header": "Error 503",
    "403-text": "Sorry, you are forbidden from accessing this page.",
    "404-text": "Sorry, the page you are looking for could not be found.",
    "500-text": "Whoops, something went wrong on our servers.",
    "503-text": "Sorry, we are doing some maintenance. Please check back soon."
  },
  "de": {
    "title": "Fehler",
    "403-header": "Zugang verweigert",
    "404-header": "Fehler 404",
    "500-header": "Fehler 500",
    "503-header": "Fehler 503",
    "403-text": "Es tut uns leid, aber Sie haben keine Zugriffsberechtigung für die angeforderte Seite.",
    "404-text": "Die angeforderte Seite gibt es hier leider nicht.",
    "500-text": "Hoppla, auf dem Server ist etwas schiefgelaufen.",
    "503-text": "Es werden Wartungsarbeiten durchgeführt. Bitte versuchen Sie es später nochmal."
  }
}
</i18n>

<template>
  <div class="flex flex-col items-center text-gray-100">
    <h1 class="text-3xl">
      {{ header }}
    </h1>

    <p class="mt-3 text-center text-lg leading-tight">
      {{ text }}
    </p>

    <Icon name="emoji-sad" class="mt-10 size-24" />
  </div>
</template>

<script>
import Layout from '@/Layouts/Mini.vue';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },
  layout: Layout,
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  computed: {
    header() {
      return this.$t(`${this.status}-header`);
    },
    text() {
      return this.$t(`${this.status}-text`);
    },
  },
};
</script>
