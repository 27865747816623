<i18n>
{
  "en": {
    "users": "Users",
    "edit": "Edit",
    "delete": "Delete",
    "delete-confirm": "Are you sure you want to delete this user?",
    "restore-confirm": "Are you sure you want to restore this user?",
    "save": "Save"
  },
  "de": {
    "users": "Benutzer",
    "edit": "Bearbeiten",
    "delete": "Löschen",
    "delete-confirm": "Diesen Benutzer wirklich löschen?",
    "restore-confirm": "Diesen Benutzer wirklich wiederherstellen?",
    "save": "Speichern"
  }
}
</i18n>

<template>
  <div>
    <bread-crumbs
      v-if="$page.props.auth.can.list_users"
      :href-back="$routes.users()"
      :items="[
        {
          text: $t('users'),
          href: $routes.users(),
        },
        {
          text: $t('edit'),
        },
      ]"
    />

    <page-title :title="form.name" />

    <page-content>
      <user-form v-model="form" :can="can" :site="site" @submit="submit" />

      <footer-actions>
        <div class="flex w-full justify-between">
          <action-button
            as="button"
            type="button"
            icon="trash"
            tabindex="-1"
            :title="$t('delete')"
            @click="destroy"
          />

          <action-button
            v-if="can.update_user"
            as="button"
            kind="primary"
            type="button"
            :loading="sending"
            @click="submit"
          >
            {{ $t('save') }}
          </action-button>
        </div>
      </footer-actions>
    </page-content>
  </div>
</template>

<script>
import UserForm from './_Form.vue';

export default {
  metaInfo() {
    return {
      title: this.form.name,
    };
  },
  components: {
    UserForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
    site: {
      type: Boolean,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: this.user,
    };
  },
  methods: {
    submit() {
      let data = new FormData();
      data.append('user[name]', this.form.name || '');
      data.append('user[email]', this.form.email || '');
      data.append('user[password]', this.form.password || '');
      data.append(
        'user[password_confirmation]',
        this.form.password_confirmation || '',
      );

      this.form.site_permissions.forEach((site_permission) => {
        data.append(
          'user[site_permissions_attributes][][id]',
          site_permission.id,
        );
        data.append(
          'user[site_permissions_attributes][][role]',
          site_permission.owner ? 'owner' : 'visitor',
        );
        data.append(
          'user[site_permissions_attributes][][subscribed_to_newsletter]',
          site_permission.subscribed_to_newsletter,
        );
      });

      data.append('user[avatar]', this.form.avatar || '');
      data.append('_method', 'put');

      var path = this.user.id
        ? this.$routes.user(this.user.id)
        : this.$routes.me();
      this.$inertia.post(path, data, {
        onStart: () => (this.sending = true),
        onFinish: () => {
          this.sending = false;
          if (
            this.$page.props.errors &&
            Object.keys(this.$page.props.errors).length === 0
          ) {
            this.form.avatar = null;
            this.form.password = null;
            this.form.password_confirmation = null;
          }
        },
      });
    },
    destroy() {
      if (confirm(this.$t('delete-confirm'))) {
        this.$inertia.delete(this.$routes.user(this.user.id));
      }
    },
    restore() {
      if (confirm(this.$t('restore-confirm'))) {
        this.$inertia.put(this.$routes.restore_user(this.user.id));
      }
    },
  },
};
</script>
