<i18n>
{
  "en": {
    "title": "Create new site",
    "save": "Create"
  },
  "de": {
    "title": "Neue Seite erstellen",
    "save": "Erstellen"
  }
}
</i18n>

<template>
  <div>
    <page-title :title="$t('title')" />

    <page-content>
      <site-form v-model="form" :site="site" @submit="submit" />

      <footer-actions>
        <action-button
          :loading="sending"
          as="button"
          type="button"
          kind="primary"
          @click="submit"
        >
          {{ $t('save') }}
        </action-button>
      </footer-actions>
    </page-content>
  </div>
</template>

<script>
import SiteForm from './_Form.vue';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },
  components: {
    SiteForm,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: this.site,
    };
  },
  methods: {
    submit() {
      let data = new FormData();
      data.append('site[name]', this.form.name || '');
      data.append('site[host]', this.form.host || '');
      data.append('site[description]', this.form.description || '');
      data.append('site[logo_large]', this.form.logo_large || '');
      data.append('site[logo_small]', this.form.logo_small || '');
      data.append('site[public]', this.form.public);
      data.append('site[signup_enabled]', this.form.signup_enabled);
      data.append('site[comments_enabled]', this.form.comments_enabled);

      this.$inertia.post(this.$routes.sites(), data, {
        onStart: () => (this.sending = true),
        onFinish: () => {
          this.sending = false;
          if (
            this.$page.props.errors &&
            Object.keys(this.$page.props.errors).length === 0
          ) {
            this.form.logo_large = null;
            this.form.logo_small = null;
          }
        },
      });
    },
  },
};
</script>
