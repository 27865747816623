<i18n>
{
  "en": {
    "my-sites": "My sites",
    "login": "Login",
    "signup": "Signup"
  },
  "de": {
    "my-sites": "Meine Seiten",
    "login": "Anmelden",
    "signup": "Registrieren"
  }
}
</i18n>

<template>
  <Layout>
    <div v-if="$page.props.auth.user">
      <action-button
        :href="$routes.sites()"
        icon="chevron-right"
        icon-position="right"
        kind="primary"
        size="large"
      >
        {{ $t('my-sites') }}
      </action-button>
    </div>

    <div v-else class="grid grid-cols-2 gap-5">
      <action-button
        kind="primary"
        size="large"
        :href="$routes.new_user_registration()"
      >
        {{ $t('signup') }}
      </action-button>

      <action-button :href="$routes.new_user_session()" size="large">
        {{ $t('login') }}
      </action-button>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/Layouts/Mini.vue';

export default {
  components: { Layout },
  layout: null,
};
</script>
