<script>
import clusterImageUrl from '@/images/cluster.svg';

export default {
  props: {
    googleMaps: {
      type: Object,
      required: true,
    },

    map: {
      type: Object,
      required: true,
    },

    cluster: {
      type: Object,
      required: true,
    },

    position: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.markerObject = new this.googleMaps.Marker({
      position: this.position,
      label:
        this.cluster.item_count == 1
          ? null
          : {
              text: this.cluster.item_count.toString(),
              color: 'white',
            },
      icon:
        this.cluster.item_count == 1
          ? null
          : {
              url: clusterImageUrl,
            },
      map: this.map,
    });
  },

  beforeDestroy() {
    this.markerObject.setMap(null);
  },

  render() {
    return null;
  },
};
</script>
