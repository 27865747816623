<template>
  <full-layout
    class="bg-gray-700"
    :nav-bar="!!$page.props.auth && !!$page.props.auth.user"
  >
    <div class="mx-auto flex max-w-md grow flex-col justify-center space-y-12">
      <div class="w-full">
        <inertia-link :href="$routes.root()">
          <logo size="large" role="banner" />
        </inertia-link>
      </div>

      <div class="w-full">
        <slot />
      </div>
    </div>
  </full-layout>
</template>

<script>
import FullLayout from '@/Layouts/Full.vue';

export default {
  components: {
    FullLayout,
  },

  computed: {
    hasContent() {
      return !!this.$slots.default;
    },
  },
};
</script>
