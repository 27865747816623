<i18n>
{
  "en": {
    "title": "Add user",
    "users": "Users",
    "add": "Add",
    "save": "Save"
  },
  "de": {
    "title": "Neuer Benutzer",
    "users": "Benutzer",
    "add": "Neu",
    "save": "Speichern"
  }
}
</i18n>

<template>
  <div>
    <bread-crumbs
      :href-back="$routes.users()"
      :items="[
        {
          text: $t('users'),
          href: $routes.users(),
        },
        {
          text: $t('add'),
        },
      ]"
    />

    <page-title :title="form.name || $t('add')" />

    <page-content>
      <user-form
        v-model="form"
        :can="{ manage_user: true }"
        :site="site"
        @submit="submit"
      />

      <footer-actions>
        <action-button
          as="button"
          type="button"
          kind="primary"
          :loading="sending"
          @click="submit"
        >
          {{ $t('save') }}
        </action-button>
      </footer-actions>
    </page-content>
  </div>
</template>

<script>
import UserForm from './_Form.vue';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },
  components: {
    UserForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    site: {
      type: Boolean,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: this.user,
    };
  },
  methods: {
    submit() {
      let data = new FormData();
      data.append('user[name]', this.form.name || '');
      data.append('user[email]', this.form.email || '');
      data.append('user[password]', this.form.password || '');
      data.append(
        'user[password_confirmation]',
        this.form.password_confirmation || '',
      );
      data.append('user[owner]', this.form.owner ? '1' : '0');
      data.append('user[avatar]', this.form.avatar || '');

      this.$inertia.post(this.$routes.users(), data, {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
  },
};
</script>
