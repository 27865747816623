<i18n>
{
  "en": {
    "title": "Login",
    "email": "Email",
    "password": "Password",
    "remember-me": "Remember me",
    "forgot-password": "Forgot password?",
    "login": "Login",
    "sign-up": "New user?"
  },
  "de": {
    "title": "Anmelden",
    "email": "E-Mail",
    "password": "Passwort",
    "remember-me": "Zukünftig automatisch anmelden",
    "forgot-password": "Passwort vergessen?",
    "login": "Anmelden",
    "sign-up": "Neu hier?"
  }
}
</i18n>

<template>
  <div>
    <form
      class="overflow-hidden rounded-lg bg-white shadow-xl dark:bg-gray-900"
      @submit.prevent="submit"
    >
      <div class="p-8">
        <h1 class="text-center text-3xl font-bold">
          {{ $t('title') }}
        </h1>
        <div class="mx-auto mt-6 w-24 border-b-2" />
        <text-input
          v-model="form.email"
          :autofocus="true"
          class="mt-10"
          :label="$t('email')"
          :errors="$page.props.errors && $page.props.errors.email"
          type="email"
          autocomplete="email"
          autocapitalize="off"
        />
        <text-input
          v-model="form.password"
          class="mt-6"
          :label="$t('password')"
          :errors="$page.props.errors && $page.props.errors.password"
          type="password"
          autocomplete="current-password"
        />
        <label class="mt-6 flex select-none items-center" for="remember">
          <input
            id="remember"
            v-model="form.remember_me"
            class="form-checkbox"
            type="checkbox"
          />
          <span class="ml-2 text-sm">
            {{ $t('remember-me') }}
          </span>
        </label>
      </div>
      <div
        class="flex items-center justify-between border-t border-gray-200 bg-gray-100 px-8 py-4 dark:border-gray-600 dark:bg-gray-800"
      >
        <inertia-link
          class="hover:underline focus:outline-none"
          tabindex="-1"
          :href="$routes.new_user_password()"
        >
          {{ $t('forgot-password') }}
        </inertia-link>
        <action-button
          as="button"
          type="submit"
          kind="primary"
          :enabled="isPresent"
          :loading="sending"
        >
          {{ $t('login') }}
        </action-button>
      </div>
    </form>

    <div v-if="$page.props.auth.can.signup" class="py-5 text-center">
      <inertia-link
        class="text-gray-100 hover:underline focus:outline-none"
        tabindex="-1"
        :href="$routes.new_user_registration()"
      >
        {{ $t('sign-up') }}
      </inertia-link>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/Mini.vue';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },
  layout: Layout,
  data() {
    return {
      sending: false,
      form: {
        email: null,
        password: null,
        remember_me: null,
      },
    };
  },
  computed: {
    isPresent() {
      return !!this.form.email && !!this.form.password;
    },
  },
  methods: {
    submit() {
      this.$inertia.post(
        this.$routes.user_session(),
        {
          user: this.form,
        },
        {
          onStart: () => (this.sending = true),
          onFinish: () => (this.sending = false),
        },
      );
    },
  },
};
</script>
