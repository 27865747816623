<template>
  <intersect v-if="nextUrl" @enter="onEnter">
    <div class="my-8 flex justify-center">
      <icon name="loading" class="size-12" />
    </div>
  </intersect>
</template>

<script>
import Intersect from 'vue-intersect';
import axios from 'axios';

export default {
  components: { Intersect },

  props: {
    propName: {
      type: String,
      required: true,
    },
  },

  computed: {
    records() {
      return this.$page.props[this.propName];
    },

    nextUrl() {
      return this.url(this.records.meta.next);
    },
  },

  methods: {
    onEnter() {
      this.load(this.nextUrl);
    },

    url(pageNumber) {
      return pageNumber
        ? this.records.meta.scaffold_url.replace(/__pagy_page__/, pageNumber)
        : null;
    },

    load(url) {
      if (this.loading) return;
      this.loading = true;

      axios
        .get(url, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          this.records.meta = response.data.meta;
          this.records.data.push(...response.data.data);
          this.loading = false;
        });
    },
  },
};
</script>
