<template>
  <div>
    <label v-if="label" class="form-label" :for="id">{{ label }}</label>
    <select
      :id="id"
      ref="input"
      v-model="selected"
      v-autofocus="autofocus"
      v-bind="$attrs"
      class="form-select"
      :class="{ error: errors.length }"
    >
      <slot />
    </select>
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `select-input-${this._uid}`;
      },
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    selected(selected) {
      this.$emit('input', selected);
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
  },
};
</script>
