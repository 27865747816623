<template>
  <component
    :is="as"
    :href="href"
    class="flex items-center gap-x-3 rounded-md border transition duration-150 ease-in-out focus:outline-none focus:ring"
    :class="{
      'border-transparent bg-gray-200 text-black hover:bg-gray-300 focus:border-gray-600 active:bg-gray-500':
        kind == 'default',
      'border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:border-blue-600 active:bg-blue-500':
        kind == 'primary',
      'border-gray-300 bg-transparent text-gray-800 hover:bg-gray-200 focus:border-gray-600 active:bg-gray-500':
        kind == 'secondary',
      'px-5 py-2 text-xl lg:py-3 lg:text-2xl': size == 'large',
      'px-4 py-2 text-base font-medium leading-5': size == 'small',
      'flex-row-reverse': iconPosition == 'right',
      'flex-row': iconPosition == 'left',
      'cursor-not-allowed opacity-50': !enabled,
      'text-center': !icon,
    }"
    :disabled="!enabled || loading"
    @click="$emit('click')"
  >
    <template v-if="icon || loading">
      <icon
        :name="loading ? 'loading' : icon"
        :class="{
          'text-gray-700': kind == 'default',
          'text-gray-200': kind == 'primary',
          'text-blue-500': active,
          'w-10': size == 'large',
          'w-5': size == 'small',
        }"
      />
    </template>

    <div v-if="$slots.default" class="grow">
      <slot />
    </div>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    as: {
      type: String,
      validator: (val) => ['button', 'inertia-link'].includes(val),
      default: 'inertia-link',
    },
    kind: {
      type: String,
      validator: (val) => ['default', 'primary', 'secondary'].includes(val),
      default: 'default',
    },
    size: {
      type: String,
      validator: (val) => ['small', 'large'].includes(val),
      default: 'small',
    },
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      validator: (val) => ['left', 'right'].includes(val),
      default: 'left',
    },
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
