// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../styles/application.css';

import Vue from 'vue';

import HoneybadgerVue from '@honeybadger-io/vue';

const honeybadgerApiKey = document.querySelector(
  'meta[name="honeybadger-api-key"]',
).content;
const version = document.querySelector('meta[name="version"]').content;

if (honeybadgerApiKey && version)
  Vue.use(HoneybadgerVue, {
    apiKey: honeybadgerApiKey,
    environment: 'production',
    revision: version,
  });

import '@/utils/shared_components';
import browserLanguage from '@/utils/browser-language';
import i18n from '@/utils/i18n';
import getCookieValue from '@/utils/cookies';

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import VueTimeago from 'vue-timeago';
import en from 'date-fns/locale/en';
import de from 'date-fns/locale/de';
Vue.use(VueTimeago, {
  locales: {
    en: en,
    de: de,
  },
  locale: browserLanguage(),
});

import VueMasonry from 'vue-masonry-css';
Vue.use(VueMasonry);

import { createInertiaApp, router, Head, Link } from '@inertiajs/vue2';

import Plausible from 'plausible-tracker';
const plausibleUrl = document.querySelector(
  'meta[name="plausible-url"]',
).content;
if (plausibleUrl) {
  const plausible = Plausible({
    domain: document.querySelector('meta[name="app-host"]').content,
    apiHost: plausibleUrl,
  });

  router.on('navigate', () => {
    plausible.trackPageview();
  });
}

Vue.directive('autofocus', {
  // Enable:
  //   v-autofocus="true"
  //   OR
  //   v-autofocus
  //
  // Disable:
  //   v-autofocus="false"
  inserted(el, binding) {
    if (binding.value || binding.value == undefined) el.focus();
  },
});

Vue.component('InertiaHead', Head);
Vue.component('InertiaLink', Link);

import * as Routes from '@/utils/routes.js';
Vue.prototype.$routes = Routes;

import FullLayout from '@/Layouts/Full.vue';
const pages = import.meta.glob('../Pages/**/*.vue', { eager: true });

createInertiaApp({
  resolve: async (name) => {
    const component = await pages[`../Pages/${name}.vue`];
    if (!component)
      throw new Error(
        `Unknown page ${name}. Is it located under Pages with a .vue extension?`,
      );

    // Get XSRF token from cookie and replace the CSRF meta tag (used by DirectUpload)
    var token = decodeURIComponent(getCookieValue('XSRF-TOKEN'));
    if (token) {
      const metaTag = document.querySelector('meta[name="csrf-token"]');
      if (metaTag) metaTag.setAttribute('content', token);
    }

    component.default.layout =
      component.default.layout === undefined
        ? FullLayout
        : component.default.layout;

    return component;
  },

  setup({ el, App, props, plugin }) {
    const appName = document
      .querySelector('meta[name="application-name"]')
      .getAttribute('content');

    Vue.use(plugin);
    new Vue({
      metaInfo: {
        titleTemplate: (title) => (title ? `${title} :: ${appName}` : appName),
      },
      i18n,
      render: (h) => h(App, props),
    }).$mount(el);
  },
});
