<i18n>
{
  "en": {
    "search": "Search result for"
  },
  "de": {
    "search": "Suchergebnis für"
  }
}
</i18n>

<template>
  <div class="mt-4">
    <portal to="subnav">
      <post-filter :params="params" :counter="currentCounter" />
    </portal>

    <template v-if="currentSearchString()">
      <bread-crumbs
        class="mt-16"
        :href-back="$routes.root()"
        :items="[
          {
            text: $t('search'),
          },
        ]"
      />

      <page-title :title="currentSearchString()" />
    </template>

    <page-title
      v-else-if="!$page.props.auth.user"
      class="mt-16"
      :title="site.name"
    >
      <p>{{ site.description }}</p>
    </page-title>

    <page-content>
      <div
        v-if="currentView == null"
        class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
      >
        <post-card
          v-for="post in posts.data"
          :key="post.id"
          :post="post"
          class="col-span-1"
          @click="onClick"
        />
      </div>

      <div v-else-if="currentView === 'calendar'">
        <section
          v-for="(month, index) in Object.keys(groupedPostsData)"
          :key="index"
        >
          <section-title>
            {{ month }}
          </section-title>

          <div
            class="mb-16 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6"
          >
            <post-card
              v-for="(post, innerIndex) in groupedPostsData[month]"
              :key="innerIndex"
              :post="post"
              :show-meta="false"
            />
          </div>
        </section>
      </div>

      <pagination prop-name="posts" />
    </page-content>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import groupBy from 'lodash/groupBy';
import PostCard from './_Card.vue';
import PostFilter from './_Filter.vue';

export default {
  components: {
    PostCard,
    PostFilter,
  },

  props: {
    posts: {
      type: Object,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    counter: {
      type: Object,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentPosts: this.posts,
      currentCounter: this.counter,
    };
  },

  computed: {
    postsData: {
      get() {
        return this.posts.data;
      },
      set(newValue) {
        this.currentPosts.data = newValue;
      },
    },

    postsMeta: {
      get() {
        return this.posts.meta;
      },
      set(newValue) {
        this.currentPosts.meta = newValue;
      },
    },

    groupedPostsData() {
      return groupBy(this.postsData, (post) => {
        const date = new Date(post.date);
        return date.toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        });
      });
    },

    currentView() {
      return this.params.view;
    },
  },

  remember: ['postsData', 'postsMeta', 'currentCounter'],

  methods: {
    currentSearchString() {
      const urlPrefix = this.$routes.search_posts() + '/';

      if (location.pathname.startsWith(urlPrefix))
        return capitalize(decodeURI(location.pathname.replace(urlPrefix, '')));
      else return null;
    },

    onClick(post) {
      if (post.unread) {
        // Remove unread marker for this post
        post.unread = false;

        // Decrease unread counter
        this.currentCounter.unread -= 1;
      }
    },
  },
};
</script>
