<i18n>
{
  "en": {
    "posts": "Posts",
    "edit": "Edit",
    "save": "Save",
    "published": "Published",
    "delete": "Delete",
    "delete-confirm": "Are you sure you want to delete this post?"
  },
  "de": {
    "posts": "Beiträge",
    "edit": "Bearbeiten",
    "save": "Speichern",
    "published": "Veröffentlicht",
    "delete": "Löschen",
    "delete-confirm": "Diesen Beitrag wirklich löschen?"
  }
}
</i18n>

<template>
  <div>
    <bread-crumbs
      :href-back="$routes.post(post.slug)"
      :items="[
        {
          text: $t('posts'),
        },
        {
          text: $t('edit'),
        },
      ]"
    />

    <page-title :title="form.title">
      <div
        v-if="!post.published"
        slot="title"
        class="ml-3 size-8 rounded-full bg-blue-200"
      />
    </page-title>

    <page-content>
      <post-form v-model="form" :clips="clips" />

      <footer-actions>
        <div class="flex w-full justify-between">
          <action-button
            as="button"
            type="button"
            icon="trash"
            :title="$t('delete')"
            tabindex="-1"
            @click="destroy"
          />

          <div class="flex items-center">
            <toggle-input v-model="form.published" :label="$t('published')" />

            <action-button
              as="button"
              type="button"
              kind="primary"
              :loading="sending"
              :disabled="uploadInProgress"
              class="ml-4"
              :title="
                uploadInProgress ? 'Upload in progress, please wait...' : ''
              "
              @click="submit()"
            >
              {{ $t('save') }}
            </action-button>
          </div>
        </div>
      </footer-actions>
    </page-content>
  </div>
</template>

<script>
import PostForm from './_Form.vue';

export default {
  metaInfo() {
    return { title: this.form.title };
  },
  components: {
    PostForm,
  },
  remember: ['form'],
  props: {
    post: {
      type: Object,
      required: true,
    },
    clips: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sending: false,
      form: this.post,
    };
  },
  computed: {
    // clipsToRemember: {
    //   get() {
    //     // Remember clips array, but without imageUrl (because it contains
    //     // images as bytestream, which is too much data)
    //     return this.clips.map(clip => ({
    //       ...clip,
    //       imageUrl: null,
    //     }))
    //   },
    //
    //   set(newValue) {
    //     this.clips = newValue
    //   },
    // },

    uploadInProgress() {
      return this.clips.some((clip) => {
        return clip.progress >= 0 && clip.progress < 100;
      });
    },
  },
  methods: {
    submit() {
      this.$inertia.put(this.$routes.post(this.post.slug), this.form, {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
    destroy() {
      if (confirm(this.$t('delete-confirm'))) {
        this.$inertia.delete(this.$routes.post(this.post.slug));
      }
    },
  },
};
</script>
