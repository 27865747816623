<i18n>
{
  "en": {
    "activities": "Recent activity",
    "post.create": "creates a post",
    "post.update": "updates a post",
    "post.publish": "publishes a post",
    "post.destroy": "deletes a post",
    "comment.create": "comments",
    "comment.destroy": "deletes a comment"
  },
  "de": {
    "activities": "Jüngste Aktivität",
    "post.create": "erstellt einen Betrag",
    "post.update": "aktualisiert einen Beitrag",
    "post.publish": "veröffentlicht einen Beitrag",
    "post.destroy": "löscht einen Beitrag",
    "comment.create": "kommentiert",
    "comment.destroy": "entfernt einen Kommentar"
  }
}
</i18n>

<template>
  <div>
    <page-title :title="$t('activities')" />

    <div
      v-for="activity in activities.data"
      :key="activity.id"
      class="activity my-10"
    >
      <div class="flex items-center">
        <img
          v-if="activity.user.avatar"
          class="size-10 rounded-full"
          width="64"
          height="64"
          :src="activity.user.avatar"
          :alt="activity.user.name"
          :title="activity.user.name"
        />
        <icon
          v-else
          name="user-circle-black"
          class="size-10 rounded-full text-gray-400"
          :title="activity.user.name"
        />

        <span class="ml-3 leading-snug">
          {{ activity.user.name }}

          {{ $t(activity.key) }}

          <inertia-link
            v-if="activity.key.includes('comment.')"
            class="font-bold text-gray-600"
            :href="$routes.post(activity.post.slug)"
          >
            {{ activity.post.title }}
          </inertia-link>

          <timeago
            :datetime="activity.created_at"
            :auto-update="60"
            class="ml-1 text-sm text-gray-500"
          />
        </span>
      </div>

      <div class="ml-12 mt-3">
        <div v-if="activity.key.includes('comment.')" class="ml-1">
          <speech-bubble arrow="up" v-html="activity.comment.content" />
        </div>

        <post-card
          v-if="activity.key.includes('post.')"
          :post="activity.post"
          class="block max-w-sm"
        />
      </div>
    </div>

    <pagination prop-name="activities" />
  </div>
</template>

<script>
import PostCard from '@/Pages/Posts/_Card.vue';

export default {
  components: {
    PostCard,
  },

  props: {
    activities: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentActivities: this.activities,
    };
  },

  computed: {
    activitiesData: {
      get() {
        return this.activities.data;
      },
      set(newValue) {
        this.currentActivities.data = newValue;
      },
    },

    activitiesMeta: {
      get() {
        return this.activities.meta;
      },
      set(newValue) {
        this.currentActivities.meta = newValue;
      },
    },
  },

  remember: ['activitiesData', 'activitiesMeta'],
};
</script>
