<i18n>
{
  "en": {
    "users": "Users",
    "all": "All",
    "add": "Add",
    "name": "Name",
    "created-at": "Registration",
    "confirmed": "Confirmed",
    "last-seen-at": "Last seen at",
    "not-confirmed": "Not confirmed yet",
    "role": "Role",
    "role-owner": "Owner",
    "role-visitor": "Besucher"
  },
  "de": {
    "users": "Benutzer",
    "all": "Alle",
    "add": "Hinzufügen",
    "name": "Name",
    "created-at": "Registrierung",
    "confirmed": "Bestätigt",
    "last-seen-at": "Zuletzt gesehen",
    "not-confirmed": "Noch nicht bestätigt",
    "role": "Rolle",
    "role-owner": "Eigentümer",
    "role-visitor": "Besucher"
  }
}
</i18n>

<template>
  <div>
    <bread-crumbs
      :href-back="$routes.root()"
      :items="[
        {
          text: $t('users'),
          href: $routes.users(),
        },
        {
          text: $t('all'),
        },
      ]"
    />

    <page-title :title="$t('users')">
      <div class="mt-4 flex shrink-0 md:ml-4 md:mt-0">
        <span v-if="can.create_user" class="rounded-md shadow-sm">
          <action-button
            kind="secondary"
            icon="user-add"
            :href="$routes.new_user()"
          >
            {{ $t('add') }}
          </action-button>
        </span>
      </div>
    </page-title>

    <div class="mt-5 flex flex-col">
      <div
        class="-mx-5 -my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      >
        <div
          class="inline-block min-w-full overflow-hidden border-b border-gray-200 align-middle shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-medium uppercase leading-4 tracking-wider text-gray-500 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300"
                >
                  {{ $t('name') }}
                </th>
                <th
                  class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-medium uppercase leading-4 tracking-wider text-gray-500 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300"
                >
                  {{ $t('role') }}
                </th>
                <th
                  class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-medium uppercase leading-4 tracking-wider text-gray-500 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300"
                >
                  {{ $t('created-at') }}
                </th>
                <th
                  class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-medium uppercase leading-4 tracking-wider text-gray-500 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300"
                >
                  {{ $t('last-seen-at') }}
                </th>
                <th
                  class="border-b border-gray-200 bg-gray-50 px-6 py-3 dark:border-gray-500 dark:bg-gray-700"
                />
              </tr>
            </thead>
            <tbody class="bg-white dark:bg-gray-600">
              <tr
                v-for="user in users"
                :key="user.id"
                :class="{ 'cursor-pointer': user.can.edit_user }"
                @click="user.can.edit_user ? onRowClick(user) : null"
              >
                <td
                  class="whitespace-nowrap border-b border-gray-200 px-6 py-4 dark:border-gray-500"
                >
                  <div class="flex items-center">
                    <div class="shrink-0">
                      <img
                        v-if="user.avatar"
                        class="size-10 rounded-full"
                        :src="user.avatar"
                        alt=""
                        width="64"
                        height="64"
                      />
                      <icon
                        v-else
                        name="user-circle-black"
                        class="size-10 rounded-full text-gray-400"
                      />
                    </div>
                    <div class="ml-4">
                      <div
                        class="text-base font-medium leading-5 text-gray-900 dark:text-gray-300"
                      >
                        {{ user.name }}
                      </div>
                      <div
                        class="text-base leading-6 text-gray-500 dark:text-gray-400"
                      >
                        {{ user.email }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-base leading-5 text-gray-500 dark:border-gray-500 dark:text-gray-300"
                >
                  {{ user.owner ? $t('role-owner') : $t('role-visitor') }}
                </td>

                <td
                  class="whitespace-nowrap border-b border-gray-200 px-6 py-4 dark:border-gray-500"
                >
                  <div
                    class="text-sm leading-5 text-gray-900 dark:text-gray-300"
                  >
                    {{ $d(new Date(user.created_at), 'datetime') }}
                  </div>

                  <div
                    class="flex items-center text-sm leading-6 text-gray-500 dark:text-gray-400"
                  >
                    <icon
                      :name="user.confirmed_at ? 'check-circle' : 'x-circle'"
                      class="mr-1.5 size-5 shrink-0"
                      :class="
                        user.confirmed_at ? 'text-emerald-500' : 'text-red-500'
                      "
                    />
                    <span>
                      {{
                        user.confirmed_at
                          ? $t('confirmed')
                          : $t('not-confirmed')
                      }}
                    </span>
                  </div>
                </td>

                <td
                  class="whitespace-nowrap border-b border-gray-200 px-6 py-4 dark:border-gray-500"
                >
                  <timeago
                    v-if="user.last_seen_at"
                    :datetime="user.last_seen_at"
                    :auto-update="60"
                    class="text-sm leading-5 text-gray-900 dark:text-gray-300"
                  />
                </td>

                <td class="w-6 border-b px-6 py-4 dark:border-gray-500">
                  <icon
                    v-if="user.can.edit_user"
                    name="chevron-right"
                    class="size-6"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('users'),
    };
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onRowClick(user) {
      this.$inertia.visit(this.$routes.edit_user(user.id));
    },
  },
};
</script>
