<i18n>
{
  "en": {
    "title": "My sites",
    "new-first": "Create my first site",
    "new-more": "Create new site"
  },
  "de": {
    "title": "Meine Seiten",
    "new-first": "Seite erstellen",
    "new-more": "Weitere Seite erstellen"
  }
}
</i18n>

<template>
  <div class="text-white">
    <h1
      v-if="sites.length > 0"
      class="text-center text-2xl font-medium text-gray-300"
    >
      {{ $t('title') }}
    </h1>

    <div
      v-for="site in sites"
      :key="site.host"
      class="mt-5 rounded bg-gray-600 p-3 hover:bg-gray-500"
    >
      <a :href="site.url" class="flex items-center p-2">
        <div class="shrink-0 rounded-full bg-gray-300">
          <img
            :src="site.logo"
            alt="Logo"
            class="size-16 p-3"
            width="64"
            height="64"
          />
        </div>
        <div class="grow px-5">
          <h1 class="text-xl font-medium leading-tight">
            {{ site.name }}
          </h1>

          <p class="mt-1 leading-normal text-gray-200">
            {{ site.description }}
          </p>
        </div>
        <div>
          <Icon name="chevron-right" class="w-10 text-gray-400" />
        </div>
      </a>
    </div>

    <action-button
      :href="$routes.new_site()"
      icon="plus"
      icon-position="right"
      size="large"
      class="mt-5"
    >
      <template v-if="sites.length == 0">
        {{ $t('new-first') }}
      </template>
      <template v-else>
        {{ $t('new-more') }}
      </template>
    </action-button>
  </div>
</template>

<script>
import Layout from '@/Layouts/Mini.vue';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },
  layout: Layout,
  props: {
    sites: {
      type: Array,
      required: true,
    },
  },
};
</script>
