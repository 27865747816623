<i18n>
{
  "en": {
    "search": "Search"
  },
  "de": {
    "search": "Suche"
  }
}
</i18n>

<template>
  <autocomplete
    id="search"
    ref="autocomplete"
    :search="onSearch"
    :auto-select="true"
    :default-value="value"
    :debounce-time="300"
    :placeholder="$t('search') + '...'"
    :aria-label="$t('search')"
    @submit="onSubmit"
  />
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import Mousetrap from 'mousetrap';

export default {
  components: { Autocomplete },
  props: {
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      query: null,
    };
  },

  mounted() {
    // Add shortcut to search input when pressing the "/" key
    Mousetrap.bind('/', (e) => {
      e.preventDefault();
      this.$refs.autocomplete.$refs.input.focus();
    });
  },

  methods: {
    onSearch(input) {
      if (input.length < 1) {
        return [];
      }

      return new Promise((resolve) => {
        fetch(this.$routes.autocomplete(input)).then((response) => {
          resolve(response.json());
        });
      });
    },

    onSubmit(query) {
      if (!query) return;

      this.$inertia.get(
        this.$routes.search_posts(query),
        {},
        {
          replace: true,
          onFinish: () => {
            if (this.$refs.autocomplete) this.$refs.autocomplete.value = '';
          },
        },
      );
    },
  },
};
</script>

<style lang="css">
.autocomplete-input {
  @apply rounded-md border-transparent bg-gray-700 py-2 text-gray-300 transition duration-150 ease-in-out sm:text-sm;
}

.autocomplete-input:focus {
  @apply bg-white text-gray-900;
}

.autocomplete-input[aria-expanded='true'] {
  @apply rounded-b-none;
}

.autocomplete-input::placeholder {
  @apply font-medium text-gray-400;
}
</style>
