<i18n>
{
  "en": {
    "title": "Registration",
    "name": "Name",
    "email": "Email",
    "password": "Password",
    "cancel": "Cancel",
    "register": "Register"
  },
  "de": {
    "title": "Registrierung",
    "name": "Name",
    "email": "E-Mail",
    "password": "Passwort",
    "cancel": "Abbrechen",
    "register": "Registrieren"
  }
}
</i18n>

<template>
  <div>
    <form
      class="overflow-hidden rounded-lg bg-white shadow-xl dark:bg-gray-900"
      @submit.prevent="submit"
    >
      <div class="p-8">
        <h1 class="text-center text-3xl font-bold">
          {{ $t('title') }}
        </h1>
        <div class="mx-auto mt-6 w-24 border-b-2" />

        <text-input
          v-model="form.name"
          :autofocus="true"
          :errors="$page.props.errors && $page.props.errors.name"
          class="mt-10"
          :label="$t('name')"
        />

        <text-input
          v-model="form.email"
          class="mt-10"
          :label="$t('email')"
          :errors="$page.props.errors && $page.props.errors.email"
          type="email"
          autocomplete="email"
        />

        <text-input
          v-model="form.password"
          class="mt-6"
          :label="$t('password')"
          :errors="$page.props.errors && $page.props.errors.password"
          type="password"
          autocomplete="new-password"
        />
      </div>

      <div
        class="flex items-center justify-between border-t border-gray-200 bg-gray-100 px-8 py-4 dark:border-gray-600 dark:bg-gray-800"
      >
        <inertia-link
          class="hover:underline focus:outline-none"
          tabindex="-1"
          :href="$routes.new_user_session()"
        >
          {{ $t('cancel') }}
        </inertia-link>
        <action-button
          as="button"
          type="submit"
          kind="primary"
          :enabled="isPresent"
          :loading="sending"
        >
          {{ $t('register') }}
        </action-button>
      </div>
    </form>
  </div>
</template>

<script>
import Layout from '@/Layouts/Mini.vue';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },
  layout: Layout,
  data() {
    return {
      sending: false,
      form: {
        name: null,
        email: null,
        password: null,
      },
    };
  },
  computed: {
    isPresent() {
      return !!this.form.email && !!this.form.password && !!this.form.name;
    },
  },
  methods: {
    submit() {
      this.$inertia.post(
        this.$routes.user_registration(),
        {
          user: this.form,
        },
        {
          onStart: () => (this.sending = true),
          onFinish: () => (this.sending = false),
        },
      );
    },
  },
};
</script>
