<i18n>
{
  "en": {
    "posts": "Posts",
    "add": "Add",
    "title": "New post",
    "save": "Save",
    "published": "Published",
    "in-progress": "Upload in progress, please wait..."
  },
  "de": {
    "posts": "Beiträge",
    "add": "Neu",
    "title": "Neuer Beitrag",
    "save": "Speichern",
    "published": "Veröffentlicht",
    "in-progress": "Upload wird durchgeführt, bitte warten..."
  }
}
</i18n>

<template>
  <div>
    <bread-crumbs
      :href-back="$routes.root()"
      :items="[
        {
          text: $t('posts'),
        },
        {
          text: $t('add'),
        },
      ]"
    />

    <page-title :title="$t('title')" />

    <page-content>
      <post-form v-model="form" :clips="clips" class="pb-20" @submit="submit" />

      <footer-actions>
        <toggle-input v-model="form.published" :label="$t('published')" />

        <action-button
          as="button"
          type="button"
          kind="primary"
          :loading="sending"
          :disabled="uploadInProgress"
          :title="uploadInProgress ? $t('in-progress') : ''"
          class="ml-4"
          @click="submit"
        >
          {{ $t('save') }}
        </action-button>
      </footer-actions>
    </page-content>
  </div>
</template>

<script>
import PostForm from './_Form.vue';

export default {
  components: {
    PostForm,
  },
  remember: ['form'],
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sending: false,
      form: this.post,
      clips: [],
    };
  },
  computed: {
    // clipsToRemember: {
    //   get() {
    //     // Remember clips array, but without imageUrl (because it contains
    //     // images as bytestream, which is too much data)
    //     return this.clips.map(clip => ({
    //       ...clip,
    //       imageUrl: null,
    //     }))
    //   },
    //
    //   set(newValue) {
    //     this.clips = newValue
    //   },
    // },

    uploadInProgress() {
      return this.clips.some((clip) => {
        return clip.progress >= 0 && clip.progress < 100;
      });
    },
  },
  methods: {
    submit() {
      this.$inertia.post(this.$routes.posts(), this.form, {
        onStart: () => (this.sending = true),
        onFinish: () => {
          this.sending = false;
          if (
            this.$page.props.errors &&
            Object.keys(this.$page.props.errors).length === 0
          ) {
            this.form = {};
            this.$emit('success');
          }
        },
      });
    },
  },
};
</script>
