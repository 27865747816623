<template>
  <div class="h-full">
    <div ref="googleMap" class="h-full" />

    <slot v-if="Boolean(googleMaps)" :google-maps="googleMaps" :map="map" />
  </div>
</template>

<script>
import loadGoogleMapsApi from 'load-google-maps-api';

export default {
  props: {
    mapConfig: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      googleMaps: null,
      map: null,
    };
  },

  mounted() {
    loadGoogleMapsApi({
      key: document.querySelector('meta[name="google-maps-key"]').content,
      libraries: ['places'],
    }).then((googleMaps) => {
      if (this.$refs.googleMap) {
        this.googleMaps = googleMaps;
        this.map = new this.googleMaps.Map(
          this.$refs.googleMap,
          this.mapConfig,
        );

        this.map.addListener('bounds_changed', () => {
          var bounds = this.map.getBounds();
          if (bounds) {
            var ne = bounds.getNorthEast();
            var sw = bounds.getSouthWest();
            this.$emit('bounds-changed', {
              neLng: ne.lng(),
              neLat: ne.lat(),
              swLng: sw.lng(),
              swLat: sw.lat(),
            });
          }
        });
      }
    });
  },

  beforeDestroy() {
    if (this.googleMaps) {
      this.googleMaps.event.clearInstanceListeners(this.map);
    }
  },
};
</script>
