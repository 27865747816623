<template>
  <div class="mt-5">
    <audio
      v-for="(clip, index) in post.clips.filter((clip) =>
        clip.content_type.startsWith('audio/'),
      )"
      :key="index"
      class="mb-4 w-full rounded"
      controls
    >
      <source :src="clip.fullUrl" :type="clip.content_type" />
      Your browser does not support the audio tag.
    </audio>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>
