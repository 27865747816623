<i18n>
{
  "en": {
    "title": "Map"
  },
  "de": {
    "title": "Landkarte"
  }
}
</i18n>

<template>
  <Layout :full-screen="true">
    <GoogleMap :map-config="mapConfig" @bounds-changed="debouncedUpdate">
      <template slot-scope="{ googleMaps, map }">
        <GoogleMapMarker
          v-for="cluster in loadedClusters"
          :key="
            cluster.item_count == 1
              ? `point-${cluster.top_id}`
              : `cluster-${cluster.top_id}-${cluster.item_count}`
          "
          :google-maps="googleMaps"
          :map="map"
          :cluster="cluster"
          :position="{ lat: cluster.latitude, lng: cluster.longitude }"
        />
      </template>
    </GoogleMap>
  </Layout>
</template>

<script>
import Layout from '@/Layouts/Full.vue';
import axios from 'axios';
import debounce from 'lodash/debounce';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },

  components: {
    Layout,
  },

  layout: null,

  props: {
    clusters: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loadedClusters: this.clusters,
    };
  },

  computed: {
    mapConfig() {
      return {
        center: this.loadedClusters[0]
          ? {
              lat: this.loadedClusters[0].latitude,
              lng: this.loadedClusters[0].longitude,
            }
          : { lat: 50.92, lng: 6.36 },
        zoom: this.loadedClusters[0] ? 12 : 6,
        mapTypeId: 'terrain',
      };
    },
  },

  created() {
    this.debouncedUpdate = debounce(this.onBoundsChanged, 300);
  },

  methods: {
    onBoundsChanged({ neLng, neLat, swLng, swLat }) {
      axios
        .get(
          this.$routes.map_index({
            format: 'json',
            neLng,
            neLat,
            swLng,
            swLat,
          }),
          {
            headers: { Accept: 'application/json' },
          },
        )
        .then((response) => {
          this.loadedClusters = response.data;
        });
    },
  },
};
</script>
