<i18n>
{
  "en": {
    "back": "Back"
  },
  "de": {
    "back": "Zurück"
  }
}
</i18n>

<template>
  <div>
    <nav class="sm:hidden">
      <inertia-link
        :href="hrefBack"
        class="flex items-center text-base font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:underline focus:outline-none"
      >
        <icon
          name="chevron-left"
          class="-ml-1 mr-1 size-5 shrink-0 text-gray-400"
        />
        {{ $t('back') }}
      </inertia-link>
    </nav>

    <nav class="hidden items-center text-base font-medium leading-5 sm:flex">
      <template v-for="(item, index) in items">
        <inertia-link
          v-if="item.href"
          :key="`href-${index}`"
          :href="item.href"
          class="text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:underline focus:outline-none"
        >
          {{ item.text }}
        </inertia-link>

        <span v-else :key="`text-${index}`" class="text-gray-500">
          {{ item.text }}
        </span>

        <icon
          v-if="index + 1 < items.length"
          :key="`svg-${index}`"
          name="chevron-right"
          class="mx-2 size-5 shrink-0 text-gray-400"
        />
      </template>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    hrefBack: {
      type: String,
      required: true,
    },
  },
};
</script>
