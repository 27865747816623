<i18n>
{
  "en": {
    "activities": "Recent activity",
    "add-post": "Add",
    "posts": "Posts",
    "map": "Map",
    "my-account": "My account",
    "my-sites": "My sites",
    "site-settings": "Settings",
    "manage-users": "Manage users",
    "logout": "Logout",
    "login": "Login",
    "signup": "Signup"
  },
  "de": {
    "activities": "Jüngste Aktivität",
    "add-post": "Neu",
    "posts": "Beiträge",
    "map": "Karte",
    "site-settings": "Einstellungen",
    "my-account": "Mein Benutzerkonto",
    "my-sites": "Meine Seiten",
    "manage-users": "Benutzer verwalten",
    "logout": "Abmelden",
    "login": "Anmelden",
    "signup": "Registrieren"
  }
}
</i18n>

<template>
  <nav class="bg-gray-800">
    <div class="container mx-auto px-2 sm:px-10">
      <div class="relative flex h-16 items-center justify-between">
        <div class="flex items-center px-2 lg:px-0">
          <div class="shrink-0">
            <inertia-link
              :href="$routes.root()"
              aria-label="Home"
              class="shrink-0 focus:outline-none"
              @click="isMainMenuOpen = false"
            >
              <logo size="small" />
            </inertia-link>
          </div>
          <div class="hidden lg:ml-6 lg:block">
            <div class="flex">
              <inertia-link
                v-for="item in mainMenuItems"
                :key="item.href"
                :href="item.href"
                :method="item.method"
                :as="item.as"
                class="mr-3 block w-full rounded-md px-3 py-2 text-left text-sm font-medium leading-5 transition duration-150 ease-in-out focus:bg-gray-700 focus:text-white focus:outline-none"
                :class="
                  isCurrent(item.href)
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                "
              >
                {{ item.text }}
              </inertia-link>
            </div>
          </div>
        </div>
        <div class="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="w-full max-w-lg lg:max-w-xs">
            <search-box v-if="isSite() && $page.props.auth.can.list_posts" />
          </div>
        </div>
        <div class="flex lg:hidden">
          <!-- Mobile menu button -->
          <button
            class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white focus:outline-none"
            aria-label="Main menu"
            :aria-expanded="isMainMenuOpen ? 'true' : 'false'"
            @click="isMainMenuOpen = !isMainMenuOpen"
          >
            <!-- Icon when menu is closed. -->
            <Icon
              name="menu"
              class="h6 w-6"
              :class="isMainMenuOpen ? 'hidden' : 'block'"
            />
            <!-- Icon when menu is open. -->
            <Icon
              name="x"
              class="size-6"
              :class="isMainMenuOpen ? 'block' : 'hidden'"
            />
          </button>
        </div>
        <div class="hidden lg:ml-4 lg:block">
          <div class="flex items-center">
            <div v-if="$page.props.auth.can.create_post">
              <action-button :href="$routes.new_post()" icon="plus">
                {{ $t('add-post') }}
              </action-button>
            </div>

            <inertia-link
              v-if="isSite() && $page.props.auth.can.list_posts"
              class="ml-2 shrink-0 rounded-full border-2 border-transparent p-1 text-gray-400 transition duration-150 ease-in-out hover:text-white focus:bg-gray-700 focus:text-white focus:outline-none"
              :aria-label="$t('activities')"
              :href="$routes.activities()"
            >
              <Icon name="bell" class="h6 w-6" />
            </inertia-link>

            <!-- Profile dropdown -->
            <drop-down v-if="$page.props.auth.user">
              <button
                id="user-menu"
                class="focus:shadow-solid ml-4 flex rounded-full text-sm text-white focus:outline-none"
                aria-label="User menu"
                aria-haspopup="true"
              >
                <img
                  v-if="$page.props.auth.user.avatar"
                  class="size-8 rounded-full"
                  :src="$page.props.auth.user.avatar"
                  alt=""
                  width="64"
                  height="64"
                />
                <icon
                  v-else
                  name="user-circle-black"
                  class="size-8 rounded-full text-gray-400"
                />
              </button>

              <div slot="dropdown">
                <div
                  class="absolute right-0 mt-2 w-48 origin-top-right rounded-md shadow-lg"
                >
                  <div
                    class="overflow-hidden rounded-md bg-white pt-2 ring-1 ring-black/5 dark:bg-gray-500"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <component
                      :is="item.href.startsWith('http') ? 'a' : 'InertiaLink'"
                      v-for="item in userMenuItems"
                      :key="item.href"
                      :href="item.href"
                      :method="item.method"
                      :as="item.as"
                      class="block w-full px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-gray-300 dark:hover:bg-gray-600"
                      role="menuitem"
                    >
                      {{ item.text }}
                    </component>

                    <div
                      class="mt-2 border-t bg-gray-100 px-4 py-2 text-xs leading-normal dark:bg-gray-600 dark:text-gray-300"
                    >
                      {{ $page.props.git.commit_version }}
                      <br />

                      <timeago
                        :datetime="$page.props.git.commit_time"
                        :auto-update="60"
                        class="font-light"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </drop-down>

            <div v-else>
              <action-button
                v-if="$page.props.auth.can.signup"
                kind="primary"
                class="ml-2 hidden md:inline-block"
                :href="$routes.new_user_registration()"
              >
                {{ $t('signup') }}
              </action-button>

              <action-button
                class="ml-2 hidden md:inline-block"
                :href="$routes.new_user_session()"
              >
                {{ $t('login') }}
              </action-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
      Mobile menu, toggle classes based on menu state.
    -->
    <div class="lg:hidden" :class="isMainMenuOpen ? 'block' : 'hidden'">
      <div class="px-2 pb-3 pt-2">
        <inertia-link
          v-for="(item, index) in mainMenuItems"
          :key="item.href"
          :href="item.href"
          :method="item.method"
          :as="item.as"
          class="block w-full rounded-md px-3 py-2 text-left text-base font-medium transition duration-150 ease-in-out focus:outline-none"
          :class="{
            'mt-1': index > 0,
            'bg-gray-900 text-white': isCurrent(item.href),
            'text-gray-300': !isCurrent(item.href),
          }"
          @click="isMainMenuOpen = false"
        >
          {{ item.text }}
        </inertia-link>
      </div>

      <div
        v-if="$page.props.auth.can.create_post"
        class="border-t border-gray-700 p-5"
      >
        <div class="flex">
          <action-button
            kind="primary"
            icon="plus"
            :href="$routes.new_post()"
            @click="isMainMenuOpen = false"
          >
            {{ $t('add-post') }}
          </action-button>
        </div>
      </div>

      <div
        v-if="$page.props.auth.user"
        class="border-t border-gray-700 pb-3 pt-4"
      >
        <div class="flex items-center px-5">
          <div class="shrink-0">
            <img
              v-if="$page.props.auth.user.avatar"
              class="size-8 rounded-full"
              :src="$page.props.auth.user.avatar"
              alt=""
              width="64"
              height="64"
            />
            <icon
              v-else
              name="user-circle-black"
              class="size-8 rounded-full text-gray-400"
            />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-6 text-white">
              {{ $page.props.auth.user.name }}
            </div>
            <div class="text-sm font-medium leading-5 text-gray-400">
              {{ $page.props.auth.user.email }}
            </div>
          </div>
        </div>
        <div class="mt-3 px-2">
          <component
            :is="item.href.startsWith('http') ? 'a' : 'InertiaLink'"
            v-for="(item, index) in userMenuItems"
            :key="item.href"
            :href="item.href"
            :method="item.method"
            :as="item.as"
            class="block w-full rounded-md px-3 py-2 text-left text-base font-medium transition duration-150 ease-in-out focus:outline-none"
            :class="{
              'mt-1': index > 0,
              'bg-gray-900 text-white': isCurrent(item.href),
              'text-gray-300': !isCurrent(item.href),
            }"
            @click="isMainMenuOpen = false"
          >
            {{ item.text }}
          </component>

          <div class="mt-8 px-3 py-2 text-xs leading-normal text-gray-200">
            {{ $page.props.git.commit_version }}

            <timeago
              :datetime="$page.props.git.commit_time"
              :auto-update="60"
              class="font-light"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex justify-center border-t border-gray-700 px-2 py-3"
      >
        <action-button
          v-if="$page.props.auth.can.signup"
          kind="primary"
          class="mr-2 block w-1/2"
          :href="$routes.new_user_registration()"
        >
          {{ $t('signup') }}
        </action-button>

        <action-button
          class="ml-2 block w-1/2"
          :href="$routes.new_user_session()"
        >
          {{ $t('login') }}
        </action-button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isMainMenuOpen: false,

      mainMenuItems: [
        this.isSite() &&
          this.$page.props.auth.can.list_posts && {
            text: this.$t('posts'),
            href: this.$routes.root(),
          },

        this.isSite() &&
          this.$page.props.auth.can.list_posts && {
            text: this.$t('map'),
            href: this.$routes.map_index(),
          },
      ].filter((item) => item),

      userMenuItems: [
        { text: this.$t('my-account'), href: this.$routes.me() },
        { text: this.$t('my-sites'), href: this.sitesUrl() },
        this.$page.props.auth.can.list_users && {
          text: this.$t('manage-users'),
          href: this.$routes.users(),
        },
        this.$page.props.auth.can.update_site && {
          text: this.$t('site-settings'),
          href: this.$routes.edit_site(),
        },
        {
          text: this.$t('logout'),
          href: this.$routes.destroy_user_session(),
          method: 'delete',
          as: 'button',
        },
      ].filter((item) => item),

      otherMenuItems: [
        this.$page.props.auth.can.create_post && {
          text: this.$t('add-post'),
          href: this.$routes.new_post(),
        },

        this.$page.props.auth.can.list_posts && {
          text: this.$t('activities'),
          href: this.$routes.activities(),
        },
      ].filter((item) => item),
    };
  },

  computed: {
    allMenuItems() {
      return this.mainMenuItems.concat(this.userMenuItems, this.otherMenuItems);
    },
  },

  methods: {
    appUrl() {
      return document
        .querySelector('link[rel="application"]')
        .href.replace(/\/$/, '');
    },

    canonicalUrl() {
      return document
        .querySelector('link[rel="canonical"]')
        .href.replace(/\/$/, '');
    },

    isApp() {
      return this.canonicalUrl().startsWith(this.appUrl());
    },

    isSite() {
      return !this.isApp();
    },

    sitesUrl() {
      if (this.isApp()) return this.$routes.sites();
      else return this.appUrl() + this.$routes.sites();
    },

    isCurrent(path) {
      // Check if there is an EXACT matching menu item
      const matchingItem = this.allMenuItems.find(
        (item) => item.href == window.location.pathname,
      );

      if (matchingItem && matchingItem.href != path)
        // If there is one but we are elsewhere, we are NOT on the current one
        return false;
      // Otherwise, check the beginning if the path
      else return window.location.pathname.startsWith(path);
    },
  },
};
</script>
