<template>
  <div class="mt-5">
    <video
      v-for="(clip, index) in post.clips.filter((clip) =>
        clip.content_type.startsWith('video/'),
      )"
      :key="index"
      :src="clip.fullUrl"
      :poster="clip.thumbUrl"
      :width="clip.width"
      :height="clip.height"
      class="mb-4 w-full rounded bg-gray-500"
      controls
      autoplay
    >
      Sorry, your browser doesn't support embedded videos, but don't worry, you
      can <a :href="clip.fullUrl">download it</a>
      and watch it with your favorite video player!
    </video>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>
