<!-- Source: https://github.com/adamwathan/vue-tailwind-examples -->

<template>
  <portal to="modal">
    <div v-if="showModal" class="fixed inset-0 z-50">
      <transition
        enter-active-class="transition duration-150 ease-out"
        leave-active-class="transition duration-200 ease-in"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
        @before-leave="backdropLeaving = true"
        @after-leave="backdropLeaving = false"
      >
        <div v-if="showBackdrop">
          <div class="fixed inset-0 bg-black opacity-50" />
        </div>
      </transition>

      <transition
        enter-active-class="transition duration-150 ease-out"
        leave-active-class="transition duration-200 ease-in"
        enter-class="scale-75 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-class="scale-100 opacity-100"
        leave-to-class="scale-75 opacity-0"
        appear
        @before-leave="cardLeaving = true"
        @after-leave="cardLeaving = false"
      >
        <div
          v-if="showContent"
          class="relative h-full overflow-y-auto bg-white text-center text-gray-800 dark:bg-gray-900 dark:text-gray-500"
          @click="close"
        >
          <div class="absolute inline-block h-screen w-0 align-middle" />

          <div class="inline-block rounded text-left align-middle" @click.stop>
            <div
              class="order-b flex items-center justify-between border-gray-200 bg-gray-100 px-5 py-4 dark:bg-black"
            >
              <h1 class="text-xl font-bold">
                {{ title }}
              </h1>

              <slot name="head" />

              <button
                type="button"
                class="rounded p-1 hover:bg-gray-300"
                title="Close"
                @click="close"
              >
                <icon name="x" class="size-4" />
              </button>
            </div>

            <slot />
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      showBackdrop: false,
      showContent: false,
      backdropLeaving: false,
      cardLeaving: false,
    };
  },
  computed: {
    leaving() {
      return this.backdropLeaving || this.cardLeaving;
    },
  },
  watch: {
    open: {
      handler: function (newValue) {
        if (newValue) {
          this.show();
        } else {
          this.close();
        }
      },
      immediate: true,
    },
    leaving(newValue) {
      if (newValue === false) {
        this.showModal = false;
        this.$emit('close');
      }
    },
  },
  created() {
    const onEscape = (e) => {
      if (this.showModal && e.keyCode === 27) {
        this.close();
      }
    };
    document.addEventListener('keydown', onEscape);
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape);
    });
  },
  methods: {
    show() {
      this.showModal = true;
      this.showBackdrop = true;
      this.showContent = true;
      document.body.style.setProperty('overflow', 'hidden');
    },
    close() {
      this.showBackdrop = false;
      this.showContent = false;
      document.body.style.removeProperty('overflow');
    },
  },
};
</script>
