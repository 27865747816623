<i18n>
{
  "en": {
    "close": "Close"
  },
  "de": {
    "close": "Schließen"
  }
}
</i18n>

<template>
  <div v-if="hasContent && show">
    <div
      v-if="$page.props.flash.success"
      class="flex w-full items-center justify-between bg-emerald-400"
    >
      <div class="flex items-center">
        <icon
          name="check-circle"
          class="ml-4 mr-2 size-4 shrink-0 fill-white"
        />
        <div class="py-4 text-sm font-medium text-white">
          {{ $page.props.flash.success }}
        </div>
      </div>
      <button
        type="button"
        class="group mr-2 p-2"
        :aria-label="$t('close')"
        @click="show = false"
      >
        <icon name="x" class="block size-3 fill-emerald-800" />
      </button>
    </div>
    <div
      v-else-if="$page.props.flash.alert"
      class="flex w-full items-center justify-between bg-red-400"
    >
      <div class="flex items-center">
        <icon name="x-circle" class="ml-4 mr-2 size-4 shrink-0 fill-white" />

        <div class="py-4 text-sm font-medium text-white">
          {{ $page.props.flash.alert }}
        </div>
      </div>
      <button
        type="button"
        class="group mr-2 p-2"
        :aria-label="$t('close')"
        @click="show = false"
      >
        <icon name="x" class="block size-3 fill-red-800" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  computed: {
    hasContent() {
      return (
        this.$page.props.flash &&
        (this.$page.props.flash.alert || this.$page.props.flash.success)
      );
    },
  },
  watch: {
    '$page.props.flash': {
      handler() {
        this.show = true;
        setTimeout(() => {
          this.show = false;
        }, 3000);
      },
      deep: true,
    },
  },
};
</script>
