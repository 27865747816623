<template>
  <blockquote
    class="speech-bubble"
    :class="{ 'arrow-down': arrow == 'down', 'arrow-up': arrow == 'up' }"
  />
</template>

<script>
export default {
  props: {
    arrow: {
      type: String,
      validator: (val) => ['up', 'down'].includes(val),
      default: 'down',
    },
  },
};
</script>

<style lang="css">
.speech-bubble {
  @apply relative whitespace-pre-wrap rounded-lg bg-gray-200 px-3 py-2 leading-normal text-gray-700;
}

.speech-bubble.arrow-up:after {
  @apply absolute top-0 -ml-2 -mt-2 h-0 w-0 border-8 border-t-0 border-transparent;
  content: '';
  left: 80px;
  border-bottom-color: theme('colors.gray.200');
}

.speech-bubble.arrow-down:after {
  @apply absolute bottom-0 -mb-2 -ml-2 h-0 w-0 border-8 border-b-0 border-transparent;
  content: '';
  left: 80px;
  border-top-color: theme('colors.gray.200');
}
</style>
