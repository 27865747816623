<template>
  <div>
    <label v-if="label" class="form-label" :for="id">{{ label }}</label>
    <textarea
      :id="id"
      ref="input"
      v-autofocus="autofocus"
      v-bind="$attrs"
      class="form-textarea"
      :class="{ error: errors.length }"
      :value="value"
      @input="
        $emit('input', $event.target.value);
        autoResize();
      "
    />
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `textarea-input-${this._uid}`;
      },
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.autoResize();
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    autoResize() {
      this.$refs.input.style.height = 'auto';
      this.$refs.input.style.height = `${this.$refs.input.scrollHeight}px`;
    },
  },
};
</script>
