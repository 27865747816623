import Vue from 'vue';
import VueI18n from 'vue-i18n';
import browserLanguage from '@/utils/browser-language';
Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    datetime: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  de: {
    short: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    long: {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    },
    datetime: {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

export default new VueI18n({
  locale: browserLanguage(),
  silentFallbackWarn: true,
  dateTimeFormats,
});
