<template>
  <div class="flex items-center">
    <div class="shrink-0">
      <img
        v-if="user.avatar"
        class="size-10 rounded-full"
        :src="user.avatar"
        alt=""
        width="64"
        height="64"
      />
      <icon v-else name="user-circle-black" class="size-10" />
    </div>

    <div class="ml-3">
      <p class="text-base font-medium leading-normal text-gray-900">
        {{ user.name }}
      </p>

      <div class="flex text-base leading-normal text-gray-500">
        <time :datetime="isoDate">
          {{ $d(new Date(date), 'short') }}
        </time>

        <span class="mx-1"> &middot; </span>

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    isoDate() {
      return new Date(this.date).toISOString().split('T')[0];
    },
  },
};
</script>
