<i18n>
{
  "en": {
    "title": "Change password",
    "password": "New password",
    "password-confirmation": "Confirm password",
    "cancel": "Cancel",
    "change": "Change"
  },
  "de": {
    "title": "Passwort ändern",
    "password": "Neues Passwort",
    "password-confirmation": "Passwort bestätigen",
    "cancel": "Abbrechen",
    "change": "Ändern"
  }
}
</i18n>

<template>
  <div>
    <form
      class="overflow-hidden rounded-lg bg-white shadow-xl dark:bg-gray-900"
      @submit.prevent="submit"
    >
      <div class="p-8">
        <h1 class="text-center text-3xl font-bold">
          {{ $t('title') }}
        </h1>
        <div class="mx-auto mt-6 w-24 border-b-2" />
        <text-input
          v-model="form.password"
          class="mt-10"
          :label="$t('password')"
          :errors="$page.props.errors && $page.props.errors.password"
          type="password"
          autocomplete="new-password"
          :autofocus="true"
          required
        />
        <text-input
          v-model="form.password_confirmation"
          class="mt-10"
          :label="$t('password-confirmation')"
          :errors="
            $page.props.errors && $page.props.errors.password_confirmation
          "
          type="password"
          autocomplete="new-password"
          required
        />
      </div>
      <div
        class="flex items-center justify-between border-t border-gray-200 bg-gray-100 px-8 py-4 dark:border-gray-600 dark:bg-gray-800"
      >
        <inertia-link
          class="hover:underline"
          tabindex="-1"
          :href="$routes.new_user_session()"
        >
          {{ $t('cancel') }}
        </inertia-link>
        <action-button
          as="button"
          type="submit"
          kind="primary"
          :enabled="isPresent"
          :loading="sending"
        >
          {{ $t('change') }}
        </action-button>
      </div>
    </form>
  </div>
</template>

<script>
import Layout from '@/Layouts/Mini.vue';

export default {
  metaInfo() {
    return { title: this.$t('title') };
  },
  layout: Layout,
  data() {
    return {
      sending: false,
      form: {
        password: null,
        password_confirmation: null,
      },
    };
  },
  computed: {
    resetPasswordToken() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      return params.get('reset_password_token');
    },
    isPresent() {
      return !!this.form.password && !!this.form.password_confirmation;
    },
  },
  methods: {
    submit() {
      this.$inertia.put(
        this.$routes.user_password(),
        {
          user: {
            ...this.form,
            reset_password_token: this.resetPasswordToken,
          },
        },
        {
          onStart: () => (this.sending = true),
          onFinish: () => (this.sending = false),
        },
      );
    },
  },
};
</script>
