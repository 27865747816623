<i18n>
{
  "en": {
    "like": "I like this",
    "dislike": "I dislike this"
  },
  "de": {
    "like": "Mag ich",
    "dislike": "Mag ich nicht"
  }
}
</i18n>

<template>
  <span class="relative z-0 grid grid-cols-2">
    <action-button
      as="button"
      type="button"
      icon="thumb-up"
      kind="secondary"
      class="-mr-px rounded-r-none"
      :active="votes.my_vote === true"
      :disabled="sending"
      :title="$t('like')"
      @click="submit(votes.my_vote === true ? 'unvote' : 'like')"
    >
      <span class="tabular-nums">{{ votes.likes }}</span>
    </action-button>

    <action-button
      as="button"
      type="button"
      icon="thumb-down"
      kind="secondary"
      class="rounded-l-none"
      :active="votes.my_vote === false"
      :disabled="sending"
      :title="$t('dislike')"
      @click="submit(votes.my_vote === false ? 'unvote' : 'dislike')"
    >
      <span class="tabular-nums">{{ votes.dislikes }}</span>
    </action-button>
  </span>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    votes: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sending: false,
    };
  },

  methods: {
    submit(value) {
      this.$inertia.post(
        this.$routes.post_votes(this.post),
        { value },
        {
          preserveScroll: true,
          only: ['votes', 'flash', 'errors'],
          onStart: () => (this.sending = true),
          onFinish: () => (this.sending = false),
        },
      );
    },
  },
};
</script>
